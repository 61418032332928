import React from 'react';
import { Button } from 'react-bootstrap';
import { BsArrowRight } from 'react-icons/bs';
import { FONT } from '../utils/Font';
import './NavBar.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';

const CustomSoftware = () => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/contact-us'); // Replace '/contact' with the desired route
    };
    return (
        <div className="custom-software-container" >
            <p className="main-title" style={{ fontFamily: FONT.fontBold }}>
                <span className="highlighted" >Custom</span> Software
            </p>
            <p className="sub-title" style={{ fontFamily: FONT.fontBold }}>Development Services</p>
            <div className="text-container">
                <p className="text-line" style={{ fontFamily: FONT.primaryFont }}>At Intellection we provide you with full-stack Web, Desktop, and</p>
                <p className="text-line" style={{ fontFamily: FONT.primaryFont }}>Mobile development services.</p>
            </div>
            <Button onClick={handleNavigate} className='letdiscuss'>Connect with Us<BsArrowRight style={{ marginLeft: '18px', marginTop: '-4px' }} /></Button>
        </div>
    );
};

export default CustomSoftware;

import React from "react";
import { Row, Col } from "react-bootstrap";
import { COLORS } from "../utils/Colors";
import { FONT } from "../utils/Font";
import socailMedia from '../assets/images/social-media.png';
import healthcare from '../assets/images/healthcare.png';
import utility from '../assets/images/data-library.png';
import engineering from '../assets/images/testing.png';
import education from '../assets/images/graduation.png';
import "./OurClient.css"
const Data = [
    {
        img: socailMedia,
        heading: 'Media & Entertainment',
    },
    {
        img: healthcare,
        heading: 'Health Care',
    },
    {
        img: utility,
        heading: 'Utility Solutions',
    },
    {
        img: engineering,
        heading: 'Manufacturing',
    },
    {
        img: education,
        heading: 'Education',
    },
];

const OurExpertise = () => {
    return (
        <>
            <div className="WhatWeDoComponent" style={{ marginTop: 70 }}>
                <h3 style={{ color: COLORS.heading, fontSize: 42, fontWeight: 'bold', fontFamily: FONT.primaryFont, textAlign: 'center', marginBottom: '-15px' }}>
                    Sectors We Provide <span style={{ color: 'rgba(111, 45, 168, 1)' }}>Innovative Solutions</span>
                </h3>
                <Row style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                    {Data.map((item, index) => (
                        <Col key={index} xs={6} sm={4} md={3} lg={2} style={{ textAlign: 'center', marginBottom: '30px' }}>
                            <div className="circle-container">
                                <img src={item.img} alt={item.heading} className="circle-img" />
                            </div>
                            <h5 style={{ marginTop: '10px', fontFamily: FONT.primaryFont, color: COLORS.text, fontWeight: 'bold' }}>
                                {item.heading}
                            </h5>
                        </Col>
                    ))}
                </Row>
            </div>
        </>
    );
};

export default OurExpertise;

{/* <WhatWeDoCardComponent style={{height:'10rem'}} font={18} lg={2} md={4} top={'35%'} item={item} index={index}/> */ }
import React from 'react';
import leftImage from '../assets/images/lefttImage.png'
import rightImage from '../assets/images/rightImage.png';
import { FONT } from "../utils/Font";
import bannerVideo from '../assets/video/About.mp4';

const About = () => {

    return (
        <>
            <div style={{
                marginTop: '5%',
                padding: 50,
                textAlign: 'center',
                background: 'linear-gradient(180deg, rgba(111, 45, 168, 0.03) -54.1%, #FFFFFF 63.14%)',
                position: 'relative'
            }}>
                <img
                    src={leftImage}
                    alt="Left Decorative"
                    className="leftImage"
                    style={{
                        position: 'absolute',
                        top: 50,
                        left: 0,
                        height: '170px',
                        width: '300px'
                    }}
                />
                <img
                    src={rightImage}
                    alt="Right Decorative"
                    className="leftImage"
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        height: '100%',
                        width: 'auto'
                    }}
                />
                <p style={{ fontFamily: FONT.fontBold, fontWeight: 700, fontSize: 45, marginTop: 50 }}>
                    About <span style={{ color: 'rgba(111, 45, 168, 1)' }}>Intellection</span>
                </p>
                <div style={{
                    textAlign: 'center',
                    margin: '0 auto',

                }}>
                    <p style={{ fontFamily: FONT.primaryFont, fontSize: 20 }}>
                        "We focus on excellence, staying ahead with the latest technology
                </p>
                    <p style={{ fontFamily: FONT.primaryFont, fontSize: 20, marginTop: '-15px' }}>to deliver cutting-edge software solutions tailored to our clients' needs."</p>
                </div>
            </div>
            <video  style={{width:'100%',height:'500px',objectFit:'cover'}} className="custom-video" autoPlay loop muted>
  <source src={bannerVideo} type="video/mp4" />
  Your browser does not support the video tag.
</video>

        </>
    );
};

export default About;
